import { render, staticRenderFns } from "./NavigationView.vue?vue&type=template&id=1dd6b238&scoped=true"
import script from "./NavigationView.vue?vue&type=script&lang=js"
export * from "./NavigationView.vue?vue&type=script&lang=js"
import style0 from "./NavigationView.vue?vue&type=style&index=0&id=1dd6b238&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dd6b238",
  null
  
)

export default component.exports